//Structure of the overall analysis results
export type IntentAnalysisData = {
	majorLabel?: IntentAnalysisLabel;
	minorLabel?: IntentAnalysisLabel;
	urgencyScore?: number;
	analysisDate?: string;
};

//Structure of an individual label
export type IntentAnalysisLabel = {
	aiLabel: IntentAnalysisLabelValue;
};

//Labels returned by the BE, not for display
export const INTENT_ANALYSIS_LABELS = {
	INTERESTED: 'interested',
	NEUTRAL: 'neutral',
	NOT_INTERESTED: 'not interested',
	IRRELEVANT: 'irrelevant',
	URGENCY: 'urgency',
	AUTHORITY: 'authority',
	COMPETITOR: 'competitor',
	BUDGET: 'budget',
	TIMELINE: 'timeline',
} as const;

//Type for the ai label enum
export type IntentAnalysisLabelValue = ValueOf<typeof INTENT_ANALYSIS_LABELS>;
