import { OrgRoleTitles } from '@copilot/common/store/models/const/enum';
import { IQueryFilter } from './dataSourceFilters';
import { SearchContext } from '@copilot/common/pages/inboxV2/context/types';

export enum InboxDisplayType {
	Cards,
	Email,
}

export interface IAddOrgMemberRequest {
	firstname: string;
	lastname: string;
	email: string;
	orgRoles: OrgRoleTitles[];
	isIndividualUser: boolean;
	/**
	 * If true B/E will validate that email is not being used currently for a user belonging to another org.
	 */
	blockMultipleOrgs?: boolean;
}

export interface MeUpdateRequest {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	alternateEmail: string;
	emailCC: string[];
	emailBCC: string[];
	isNotificationEnable: boolean;
	vpnPort?: number;
}

export interface OnboardMessage {
	// nodeId is undefined in most of the usages
	nodeId?: string;
	period: number;
	text: string;
	time: number;
}

/**
 * Typing for an inmail sequence message
 */
export type InMailSequenceMessage = OnboardMessage &
	Readonly<{
		subject: string;
	}>;

export interface OnboardMessageResponse<TMessage = OnboardMessage> {
	[k: string]: TMessage[];
}

export interface OnboardCriteriaRequestModel {
	experience: string[];
	locations: string[];
	zip: string[];
	radius: string;
	titles: string[];
	extraTitles: string[];
	exclusions: string[];
	recentJobChange: boolean;
	headCount: string[];
	messages: OnboardMessage[];
}

export interface OrganizationMemberRequestModel {
	id: string;
	userId: string;
	organizationId: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	orgRoles: string[];
	vpnPort: number;
	salesNavContractName: string;
	startDate: string;
	alternateEmail: string;
	emailCC: string[];
	emailBCC: string[];
	isNotificationEnable: boolean;
	withdrawExternalInvites?: boolean;
	weeksBeforeWithdrawal?: number;
	isActive: boolean;
}

/**
 * Update model to update a property and its value
 */
export interface UpdateEventModel<T> {
	PropertyName: string;
	UpdateValue: T;
}

export interface ContactConnectionDeleteRequestModel {
	connectionId: string;
	contactId: string;
}

export enum CampaignStatusEnum {
	Unknown = -1,
	Disabled = 0,
	Enabled = 1,
	Initializing = 2,
	Running = 3,
}

export interface FilterModel {
	include: string[];
	exclude: string[];
	context?: SearchContext;
}

export interface BaseFilterRequestModel {
	CampaignId: FilterModel;
	OrgMemberId: FilterModel;
	TagName: FilterModel;
}

export interface InboxFilterRequestModel extends BaseFilterRequestModel {
	NewMessagesOnly: FilterModel;
	ShowSnoozed: FilterModel;
	ShowReminder: FilterModel;
	Sentiment: FilterModel;
	LastMessageType: FilterModel;
	ReminderStatus: FilterModel;
}

export interface ConnectionsFilterRequestModel extends BaseFilterRequestModel {
	Location: FilterModel;
	Phone: FilterModel;
	LastThreadActivity: FilterModel;
	Email: FilterModel;
	MeetingBooked: FilterModel;
}

export interface CampaignsFilterRequestModel {
	OrgMemberId: FilterModel;
}

export interface FiltersRequestModel {
	ViewType: InboxDisplayType;
	FilterBy:
		| Partial<InboxFilterRequestModel>
		| Partial<ConnectionsFilterRequestModel>
		| Partial<CampaignsFilterRequestModel>;
	SortBy: string;
	SearchTerm: string;
}

export interface IAddTagRequest {
	name: string;
	description: string;
}

/**
 * Model specifying parameters for a Query state
 * @param {string} orgId organization id
 * @param {number} page page number
 * @param {number} pageSize number of connections in page
 * @param {IQuery} queryModel model specifying http data source filter state
 */
export interface IPaginatedQuery {
	orgId: string;
	page: number;
	pageSize: number;
	queryModel: IQuery;
}

/**
 * Model of http data source filter state
 * @property {string} SortBy a valid field name of a table defining how the results are sorted e.g. "lastThreadActivity"
 * @property {boolean} OrderDescending whether to sort by descending order or not, false if specified
 * @property {string | null} SearchTerm search query, put null if unspecified
 * @property {IQueryFilter[]} FilterBy data source filter(s) specified by the sub interfaces of IQueryFilter, empty array if no filters
 */
export interface IQuery {
	SortBy: string;
	OrderDescending: boolean;
	SearchTerm: string | null;
	FilterBy: IQueryFilter[];
}

export interface CSVRequestModel {
	connectionFilters: Partial<FiltersRequestModel>;
	contactConnectionIds: string[];
	columns: string[];
}
