/**
 * Sizes for the AI tag
 */
export const AI_TAG_SIZES = {
	SMALL: 'sm',
	MEDIUM: 'md',
	LARGE: 'lg',
} as const;

/**
 * Type for the AI tag size
 */
export type AiTagSize = ValueOf<typeof AI_TAG_SIZES>;
