import { useFlags } from 'launchdarkly-react-client-sdk';

type CopilotFeatureFlags = {
	isIntentAnalysisFeatureEnabled: boolean;
	isNewAiUiFeatureEnabled: boolean;
	isNewPersonalizedInsightsFeatureEnabled: boolean;
};

/**
 * Hook to get the feature flags
 * @returns an object with the feature flags
 */
export function useFeatureFlags() {
	const flags = useFlags<CopilotFeatureFlags>();

	return {
		...flags,
	};
}
