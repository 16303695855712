import { IOrganizationTeamMember } from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/models';
import { Button, Divider, Input, Select, Form, Space, theme, Flex } from 'antd';
import { useState } from 'react';
import Text from 'antd/lib/typography/Text';
import { ERROR_ICON, OK_ICON, SYNCING_ICON } from '../../icon';
import { IProxy } from '@copilot/common/store/models/redux';
import {
	BasicOrgMemberInfoFormProps,
	BasicOrgMemberInfo,
} from '../../drawer/wrappers/adminMember/types';
import styles from './orgMemberInfo.module.less';
import { useRegionalProxies } from '@copilot/common/pages/settings/regions/useRegionalProxies';
import isNil from 'lodash/isNil';
import { disconnectMemberLinkedIn } from './serviceCalls';
import notificationManager from '@copilot/common/utils/notificationManager';
import { useDispatch } from 'react-redux';
import { OrganizationMemberManager } from '@copilot/data';
import {
	LoadOneTeamMemberAction,
	updateMemberAndGetLinkedIn,
} from '@copilot/common/pages/organizationDashboard/orgTeamMembers/data/saga';
import { HostedAuthCompletionModal } from '@copilot/common/pages/settings/linkedInAuthResults';
import {
	TEAM_SETTINGS_DRAWER_LINKEDIN_CONNECT_TRACKING_ID,
	TEAM_SETTINGS_DRAWER_SAVE_TRACKING_ID,
} from '@copilot/common/tracking/userpilotEventConsts';
import { useNavigateToHostedAuthUrl } from '@copilot/common/hooks/linkedin/hostedAuth/useNavigateToHostedAuthUrl';

type OrgMemberBasicInfoEditorProps = {
	/**
	 * orgMember to update basic info
	 */
	orgMember: IOrganizationTeamMember;
	/**
	 * whether info is editable
	 */
	isEditable: boolean;
	/**
	 * Callback on save
	 */
	onSave: (values: BasicOrgMemberInfo) => void;
	/**
	 * Callback on resent email
	 */
	onResendEmail: () => void;
	/**
	 * Callback on activate user
	 */
	onActivate: () => void;
	/**
	 * Callback on deactivate user
	 */
	onDeactivate: () => void;

	onSaveProxy: (proxy: IProxy) => Promise<void>;
};

/**
 * [Presentational] Editors for orgMember basic info
 */
function OrgMemberBasicInfoEditor({
	isEditable,
	onSave,
	orgMember,
	onResendEmail,
	onActivate,
	onDeactivate,
}: OrgMemberBasicInfoEditorProps) {
	const { token } = theme.useToken();
	const { isActive } = orgMember;
	const navigateToHostedAuthUrl = useNavigateToHostedAuthUrl(orgMember.id, `${window.location.href}&teamMembersRedirectMemberId=${orgMember.id}`);

	const [isSyncing, setIsSyncing] = useState(false);
	const [isRefreshingSalesNav, setIsRefreshingSalesNav] = useState(false);
	const [isDisconnectingLinkedIn, setIsDisconnectingLinkedIn] = useState(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const storeDispatch = useDispatch();

	const { currentUserRegion, selectProxyFromRegion, regionOptions, hasProxySet } =
		useRegionalProxies({
			userVpnPort: orgMember.vpnPort,
		});

	const [form] = Form.useForm<BasicOrgMemberInfoFormProps>();

	function onFinish(values: BasicOrgMemberInfoFormProps) {
		setIsEditing(false);
		const region = values.region;
		const newProxy = selectProxyFromRegion(region);
		onSave({
			...values,
			vpnPort:
				currentUserRegion === region || isNil(newProxy)
					? orgMember.vpnPort
					: newProxy.value,
		});
	}

	function onCancel() {
		form.resetFields();
		setIsEditing(false);
	}

	async function onDisconnectLinkedIn() {
		try {
			setIsDisconnectingLinkedIn(true);
			const updatedMember = await disconnectMemberLinkedIn(orgMember.id);
			const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(updatedMember.id);

			if (isLoggedIn === false) {
				storeDispatch(LoadOneTeamMemberAction(updateMemberAndGetLinkedIn, updatedMember));
				notificationManager.showSuccessNotification({
					message: 'Success',
					description: 'LinkedIn was successfully disconnected',
				});
			}
		} catch (e) {
			notificationManager.showErrorNotification({
				message: 'Error',
				description:
					'There was an issue disconnecting LinkedIn, please refresh and try again.',
			});
		}

		setIsDisconnectingLinkedIn(false);
	}

	async function onRefreshSalesNav() {
		try {
			setIsRefreshingSalesNav(true);
			// Prompt to login again to get the latest sales nav cookies
			await navigateToHostedAuthUrl();
		} catch (e) {
			notificationManager.showErrorNotification({
				message:
					'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
				description: "Couldn't generate LinkedIn auth URL",
			});
		} finally {
			// Add a brief delay to prevent the user from spamming the sync button after an error
			// Not relevant to the success case since the user is redirected to a new page
			await new Promise((resolve) => setTimeout(resolve, 1000));
			setIsRefreshingSalesNav(false);
		}
	}

	async function onSync() {
		try {
			setIsSyncing(true);
			const isLoggedIn = await OrganizationMemberManager.checkIsLoggedIn(orgMember.id);
			if (isLoggedIn) {
				notificationManager.showInfoNotification({
					message: 'Already Connected',
					description: 'This user is already connected to LinkedIn.',
				});
				const updatedMember = {
					...orgMember,
					isLinkedInLoggedIn: true,
				};
				storeDispatch(LoadOneTeamMemberAction(() => Promise.resolve(updatedMember)));
				return;
			}
			await navigateToHostedAuthUrl();
		} catch (e) {
			notificationManager.showErrorNotification({
				message:
					'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
				description: "Couldn't generate LinkedIn auth URL",
			});
		} finally {
			// Add a brief delay to prevent the user from spamming the sync button after an error
			// Not relevant to the success case since the user is redirected to a new page
			await new Promise((resolve) => setTimeout(resolve, 1000));
			setIsSyncing(false);
		}
	}

	return (
		<Space direction={'vertical'} style={{ width: '100%' }}>
			<Flex align="center" justify="space-between">
				<Space>
					<Text style={{ fontSize: token.fontSizeHeading3 }} strong>
						Details
					</Text>
					{isEditable && !isEditing && (
						<Button size="small" onClick={() => setIsEditing(true)}>
							Edit
						</Button>
					)}
				</Space>

				<Button onClick={onResendEmail} disabled={!isActive} size="small">
					Resend Login E-mail
				</Button>
			</Flex>

			<Divider style={{ margin: 0 }} />

			<Form
				form={form}
				initialValues={{
					firstName: orgMember.firstName,
					lastName: orgMember.lastName,
					email: orgMember.email,
					region: currentUserRegion,
				}}
				onFinish={onFinish}
				className={styles.orgMemberInfoWrapper}
			>
				<Form.Item label="Member Status">
					{isActive ? (
						<Flex gap="small">
							<Text>Active</Text>
							<Button onClick={onDeactivate} danger type="primary" size="small">
								Deactivate User
							</Button>
						</Flex>
					) : (
						<Flex gap="small">
							<Text>Inactive</Text>
							<Button
								type="primary"
								style={{ background: '#008000', borderColor: '#008000' }}
								size="small"
								onClick={onActivate}
							>
								Reactivate User
							</Button>
						</Flex>
					)}
				</Form.Item>
				<Form.Item name="firstName" label="First Name">
					{isEditing ? <Input size="small" /> : <Text>{orgMember.firstName}</Text>}
				</Form.Item>
				<Form.Item name="lastName" label="Last Name">
					{isEditing ? <Input size="small" /> : <Text>{orgMember.lastName}</Text>}
				</Form.Item>
				<Form.Item name="email" label="E-mail">
					<Text>{orgMember.email}</Text>
				</Form.Item>
				<Form.Item label="LinkedIn Status">
					{orgMember.isLinkedInLoggedIn || orgMember.isLiSessionInRetryState ? (
						<Flex gap={'small'}>
							{orgMember.isLinkedInLoggedIn ? OK_ICON : SYNCING_ICON}
							{orgMember.isLinkedInLoggedIn ? 'Connected' : 'Syncing'}
							<Button
								onClick={() => void onDisconnectLinkedIn()}
								size="small"
								type="primary"
								loading={isDisconnectingLinkedIn}
								ghost
							>
								Disconnect
							</Button>
						</Flex>
					) : (
						<Flex gap={'small'}>
							{ERROR_ICON}
							<Button
								onClick={() => void onSync()}
								disabled={!isActive}
								loading={isSyncing}
								size="small"
								type="primary"
								ghost
								data-tracking-id={TEAM_SETTINGS_DRAWER_LINKEDIN_CONNECT_TRACKING_ID}
							>
								Sync
							</Button>
						</Flex>
					)}
				</Form.Item>
				{orgMember.isLinkedInLoggedIn && (
					<Form.Item label="Sales Navigator Status">
						{orgMember.hasInvalidSalesNavSubscription ? (
							<Flex gap={'small'} align="center">
								{ERROR_ICON}
								No Subscription
								<Button
									onClick={() => void onRefreshSalesNav()}
									disabled={!isActive}
									size="small"
									type="primary"
									loading={isRefreshingSalesNav}
									ghost
								>
									Sync Sales Navigator
								</Button>
							</Flex>
						) : (
							<Flex gap={'small'}>{OK_ICON} Active</Flex>
						)}
					</Form.Item>
				)}
				<Form.Item name="region" label="Region">
					{isEditing ? (
						<Select size="small" placeholder="Not Selected">
							{regionOptions.map((region) => (
								<Select.Option key={region} value={region}>
									{region}
								</Select.Option>
							))}
						</Select>
					) : (
						<Text>{hasProxySet ? currentUserRegion : 'No region set'}</Text>
					)}
				</Form.Item>
				{isEditing && (
					<Form.Item>
						<Flex gap="small" justify="flex-end">
							<Button size="small" onClick={onCancel}>
								Cancel
							</Button>
							<Button
								size="small"
								type="primary"
								htmlType="submit"
								data-tracking-id={TEAM_SETTINGS_DRAWER_SAVE_TRACKING_ID}
							>
								Save
							</Button>
						</Flex>
					</Form.Item>
				)}
			</Form>
			<HostedAuthCompletionModal isOtherAccount={true} />
		</Space>
	);
}

export default OrgMemberBasicInfoEditor;
