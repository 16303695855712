import { Tag } from 'antd';
import styles from './betaTag.module.less';

/**
 * Icon-like component for indicating features that are in big "B" BETA
 * @returns
 */
export function BetaTag() {
	return <Tag className={styles.betaTag}>BETA</Tag>;
}
