import { InboxMessage } from '../../inbox/ui/types';
import { InboxMessageSubmissionHandler } from '../types';
import isString from 'lodash/isString';
import { IncludeExcludeFilterOption } from '../components/includeExcludeFilter/types';
import { UseRemindersHandlers } from '@copilot/common/components/drawer/wrappers/contact/useReminders/types';
import { CampaignModel } from '@copilot/common/store/selectors/campaign';
import {
	ActiveOrganizationFilter,
	OrganizationFilterConfig,
	OrganizationFilterHandlers,
	OrganizationFilterOptions,
} from '@copilot/common/components/filters/organizationFilterSection/types';
import { FilterOption } from '@copilot/common/components/filters/types';

export const SEARCH_CONTEXTS = {
	ALL_MESSAGES_CONTEXT: 'all',
	REMINDERS_CONTEXT: 'reminders',
	UNREAD_MESSAGES_CONTEXT: 'unread',
	URGENT_LEADS_CONTEXT: 'urgent',
} as const;

export type SearchContext = ValueOf<typeof SEARCH_CONTEXTS>;

// Type denoting contexts/tabs that have their own special filters
export type FilterableSearchContexts =
	| typeof SEARCH_CONTEXTS.REMINDERS_CONTEXT
	| typeof SEARCH_CONTEXTS.UNREAD_MESSAGES_CONTEXT;

/**
 * Type guard for SearchContext
 */
export function isSearchContext(val: any): val is SearchContext {
	return isString(val) && Object.values(SEARCH_CONTEXTS).includes(val as SearchContext);
}

//sentiment constants
export const ALL_SENTIMENTS = 'ALL_SENTIMENTS';
export const INTERESTED_SENTIMENT = 'Interested';
export const NOT_INTERESTED_SENTIMENT = 'NotInterested';
export const MAYBE_SENTIMENT = 'Maybe';

// Filter options for filtering by sentiment
export type SentimentFilter =
	| typeof INTERESTED_SENTIMENT
	| typeof NOT_INTERESTED_SENTIMENT
	| typeof MAYBE_SENTIMENT
	| typeof ALL_SENTIMENTS;

//reminder constants
export const UPCOMING_REMINDER = 'UPCOMING';
export const DUE_REMINDER = 'DUE';

// Filter options for filtering by reminder status
export type ReminderFilter = typeof UPCOMING_REMINDER | typeof DUE_REMINDER;

export type ContextFilterDictionary = {
	REMINDERS: ReminderFilter;
	NEW_MESSAGES: SentimentFilter;
	ALL_MESSAGES: boolean;
};

export type InboxV2GlobalFilter = {
	tags: IncludeExcludeFilterOption[];
} & ActiveOrganizationFilter;

/**
 *Filter state for the InboxV2
 */
type InboxV2UIState = {
	activeFilter: InboxV2GlobalFilter;
	searchContext: SearchContext;
	searchTerm: string;
	isLoading: boolean;
	contextFilterDictionary: ContextFilterDictionary;
} & OrganizationFilterConfig;

/**
 * Data returned for InboxV2 (should be as close to the DTO as possible FE transformation)
 */
type InboxV2Data = {
	messages: InboxMessage[];
	hasMore: boolean;
	numReminders: number;
	numUnreadMessages: number;
	numUrgentMessages: number;
	nurtureCampaigns: CampaignModel[];
};

/**
 * Handlers for actions that can be performed on a message.
 */
type InboxV2ActionHandlers = Readonly<{
	onDeleteMessages: (message: string[]) => Promise<void>;
	onMarkAsRead: (message: string[]) => Promise<void>;
	onMarkAsUnread: (message: string[]) => Promise<void>;
	onMarkReminderAsComplete: (message: InboxMessage[]) => Promise<void>;
	onApplyTags: (connectionsToMove: { id: string; name: string }[]) => Promise<void>;
	onMoveThreadsToNurtureCampaign: (
		targetNurtureCampaignId: string,
		orgMemberId: string,
		threadsToMoveIds: string[]
	) => Promise<void>;
}>;

/**
 * Handlers for the InboxV2
 */
type InboxV2Handlers = {
	onTagFilterChange: (value: IncludeExcludeFilterOption[]) => void;
	onSearchTermChange: (newTerm: string) => void;
	onSearchContextChange: (newContext: SearchContext) => void;
	onSubmitSearch: (term: string) => void;
	onSubmitInboxMessage: InboxMessageSubmissionHandler;
	onNewMessagesContextFilterChange: (newFilter: SentimentFilter) => void;
	onAllMessagesContextFilterChange: (showUnRepliedOnly: boolean) => void;
	onRemindersContextFilterChange: (newFilter: ReminderFilter) => void;
	onLoadMore: (offset?: number) => void;
	onViewConversation: (message: InboxMessage, idx: number) => void;
} & UseRemindersHandlers &
	InboxV2ActionHandlers &
	OrganizationFilterHandlers;

/**
 * Filtering options for InboxV2
 */
type InboxV2Options = {
	reminderFilterOptions: FilterOption<ReminderFilter>[];
	sentimentFilterOptions: FilterOption<SentimentFilter>[];
	tagOptions: FilterOption[];
} & OrganizationFilterOptions;

/**
 * Configuration for the InboxV2 (eg tuning values, optional changes, etc.)
 */
type InboxV2Config = {
	searchTermDebounceTime: number;
	useDebounceSearch: boolean;
};

/**
 * Net context state for the InboxV2
 */
export type InboxV2ContextData = InboxV2UIState &
	InboxV2Data &
	InboxV2Handlers &
	InboxV2Options &
	InboxV2Config;
