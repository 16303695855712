// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t4weRB4FOM4Gv5NRm6aO .anticon-loading {\n  width: 2em;\n  height: 2em;\n}\n.mKjHIeSeb0mRfX5D_DIq > * {\n  font-size: 2em;\n}\n.AsjRureYzDZPE0NuONSG {\n  height: 100vh;\n  width: 100vw;\n}\n", "",{"version":3,"sources":["webpack://./../../teams/src/components/app-loader/appLoader.module.less"],"names":[],"mappings":"AAAA;EAEE,UAAA;EACA,WAAA;AAAF;AAIA;EACC,cAAA;AAFD;AAKA;EACC,aAAA;EACA,YAAA;AAHD","sourcesContent":[".loaderIndicator {\n\t:global(.anticon-loading) {\n\t\twidth: 2em;\n\t\theight: 2em;\n\t}\n}\n\n.loaderIcon > * {\n\tfont-size: 2em;\n}\n\n.appLoader {\n\theight: 100vh;\n\twidth: 100vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderIndicator": "t4weRB4FOM4Gv5NRm6aO",
	"loaderIcon": "mKjHIeSeb0mRfX5D_DIq",
	"appLoader": "AsjRureYzDZPE0NuONSG"
};
export default ___CSS_LOADER_EXPORT___;
