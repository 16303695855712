import { FC, useState } from 'react';
import JwtDecode from 'jwt-decode';
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { Config } from '@copilot/common/config';
import BaseDataManager from '@copilot/data/managers/base';
import { useDispatch } from 'react-redux';
import { AppActions } from '@copilot/common/store/actions/app';
import { LoginResponseToken } from '@copilot/data/responses/interface';
import { OrganizationMemberActions } from '@copilot/common/store/actions/organizationMember';
import { appInsights } from '@copilot/common/components/snippets/applicationInsights';
import { tagManager } from '@copilot/common/components/snippets/googleTagManager';
import Store from '@copilot/common/store';
import { Redirect } from 'react-router';
import { useEffectAsync } from '@copilot/common/hooks/common';
import getAuthToken from '@copilot/common/utils/authToken';
import { AppLoader } from '@copilot/teams/src/components/app-loader/appLoader';

const B2cProvider: FC = ({ children }) => {
	const pca = BaseDataManager.RequestManager.getB2CInstance();
	if (!pca) throw new Error('PCA must be available to use b2c Provider');
	return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

const LegacyAuthenticationProvider: FC = ({ children }) => {
	return <>{children}</>;
};

/**
 * Merge Auth Redirect Options with required values
 * @param requestOptions Options we want to merge with
 * @returns Prepped Auth Redirect Options
 */
export const getAuthRedirectRequestOptions = (requestOptions: RedirectRequest) => {
	const { extraQueryParameters, ...rest } = requestOptions;
	return {
		...rest,
		extraQueryParameters: {
			template: Config.b2cTemplateName,
			...extraQueryParameters,
		},
	};
};

/**
 * Wrapper for components to show for authenticated users
 */
export const AuthenticatedTemplate: FC = ({ children }) => {
	const { isB2CEnabled } = Config;
	if (isB2CEnabled) {
		const { instance } = useMsal();
		const activeAccount = instance.getActiveAccount();
		const authRequest: RedirectRequest = getAuthRedirectRequestOptions({
			scopes: [],
			// A temporary fix to revent infinite redirect after switching org in firefox due to authority mismatch
			onRedirectNavigate: () => activeAccount?.homeAccountId.includes('signup_signin'),
		});
		return (
			<MsalAuthenticationTemplate
				interactionType={InteractionType.Redirect}
				authenticationRequest={authRequest}
			>
				{children}
			</MsalAuthenticationTemplate>
		);
	} else {
		return <>{children}</>;
	}
};

/**
 * Wrapper for components to show for unauthenticated users
 */
export const UnauthenticatedTemplate: FC = ({ children }) => {
	const { isB2CEnabled } = Config;
	if (isB2CEnabled) {
		return <Redirect to="/" />;
	} else {
		return <>{children}</>;
	}
};

/**
 * Authentication provider that will loaded the authenticated session
 */
const AuthenticationProvider: FC = ({ children }) => {
	const { isB2CEnabled } = Config;
	const dispatch = useDispatch();
	const [hasTokenLoaded, setHasTokenLoaded] = useState<boolean>(false);
	useEffectAsync(async () => {
		const token = await getAuthToken();
		if (token) {
			const payload = JwtDecode<LoginResponseToken>(token);
			dispatch(AppActions.loadActiveUser(payload.omid));
			dispatch(AppActions.loadActiveUserHash(payload.userHash));
			dispatch(AppActions.loadActiveOrganization(payload.oid));
			dispatch(AppActions.loadActiveTenant(payload.tid));
			if (payload.iDisplayName)
				dispatch(
					AppActions.loadActiveImpersonator({
						name: payload.iDisplayName,
						id: payload.iid,
					})
				);
			dispatch(OrganizationMemberActions.loadOrganizationMemberFromToken(payload));
		}
		if (Config.appInsightsKey) appInsights.initialize(Store.History, Config.appInsightsKey);
		if (Config.tagManagerKey) tagManager.initialize(Config.tagManagerKey);
		setHasTokenLoaded(true);
	}, []);

	if (!hasTokenLoaded) {
		return <AppLoader />;
	}

	if (isB2CEnabled) {
		return <B2cProvider>{children}</B2cProvider>;
	} else {
		return <LegacyAuthenticationProvider>{children}</LegacyAuthenticationProvider>;
	}
};

export default AuthenticationProvider;
