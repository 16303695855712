// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlVRBX4cZ4ww5AxhKvUQ {\n  vertical-align: middle;\n  font-size: 12px;\n  padding: 0 4px;\n  margin: 0;\n  font-weight: normal;\n  text-shadow: none;\n  height: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/icon/betaTag.module.less"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AACD","sourcesContent":[".betaTag {\n\tvertical-align: middle;\n\tfont-size: 12px;\n\tpadding: 0 4px;\n\tmargin: 0;\n\tfont-weight: normal;\n\ttext-shadow: none;\n\theight: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"betaTag": "GlVRBX4cZ4ww5AxhKvUQ"
};
export default ___CSS_LOADER_EXPORT___;
