// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PkI0AreKKOIRckPjzHkA {\n  background: transparent;\n  padding: 0;\n  border-radius: 10px;\n  border: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/ai/aiButton/aiButton.module.less"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,UAAA;EACA,mBAAA;EACA,SAAA;AACD","sourcesContent":[".button {\n\tbackground: transparent;\n\tpadding: 0;\n\tborder-radius: 10px;\n\tborder: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "PkI0AreKKOIRckPjzHkA"
};
export default ___CSS_LOADER_EXPORT___;
