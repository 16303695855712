import { InboxModel } from '@copilot/data/responses/models/inbox';
import { Reducer } from 'redux';
import { InboxMessageFilters } from '../ui/types';
import {
	ApplyInboxMessagesMarkAsReadSuccessActionType,
	InboxMessagesActions,
	InboxMessagesCountLoadSuccessActionType,
	InboxMessagesDismissSuccessActionType,
	InboxMessagesLoadActionType,
	InboxMessagesLoadFailedActionType,
	InboxMessagesLoadSuccessActionType,
	InboxMessagesMigrateConnectionSuccessActionType,
	InboxMessagesModifyMessageTagsActionType,
	InboxMessagesResetFilterInStoreActionType,
	InboxMessagesSendAutomatedMessageActionType,
	InboxMessagesSendManualMessageActionType,
	InboxMessagesSendMessageFailedActionType,
	InboxMessagesSendMessageSuccessActionType,
	InboxMessagesUpdateFilterActionType,
	InboxMessagesUpdatePaginationActionType,
	InboxMessagesUpdateSearchTermActionType,
	InboxMessagesUpdateSuccessActionType,
	InboxMessagesUpdateVersionActionType,
	InboxMessagesUpdateViewSuccessActionType,
	InboxPageLoadSuccessActionType,
} from './actionCreators';
import { INBOX_MESSAGES, INBOX_VERSIONS } from './constant';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { InboxDisplayType } from '@copilot/data/requests/models';
import { partition } from '@copilot/common/utils';
import { LoadMorePaginatedResponse } from '@copilot/data/responses/interface';
import { InMailStatus } from '../../../constant/inMailConstant';
import { InboxMessageType, InboxVersion } from './types';
import { SEARCH_CONTEXTS } from '../../inboxV2/context/types';

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 10;

export type InboxModelState<Filters> = {
	data?: InboxMessageType[];
	totalCount?: number;
	loading: boolean;
	error: boolean;
	offset: number;
	pageSize: number;
	version: InboxVersion;
	filter: Filters;
	viewType?: InboxDisplayType;
	counts?: Record<string, number>;
	hasMore?: boolean;
	/**
	 * The organization id of the org we are fetching messages for. Only defined for CS admins when they are impersonating an org
	 */
	csOrgId: string | undefined;
	/**
	 * Holds the last migrated threads. If no migration has happened in current session then this will be empty
	 */
	prevMigratedThreads?: InboxModel[];
	searchTerm: string;

	/* InMail data pertaining to the Inbox Message */
	inMail?: {
		status: InMailStatus;
	};

	/* Reminder data pertaining to the Inbox Message */
	reminder?: {
		reminder: boolean;
	};
};

const stateBase: InboxModelState<InboxMessageFilters> = {
	data: undefined,
	totalCount: -1,
	loading: false,
	error: false,
	offset: DEFAULT_OFFSET,
	pageSize: DEFAULT_PAGE_SIZE,
	filter: {
		newMessagesOnly: false,
		showSnoozedOnly: false,
		showReminderOnly: false,
		sentiment: [],
		tags: [],
		campaigns: [],
		teamMembers: [],
		reminderStatuses: [],
		lastMessageTypes: [],
		searchContext: SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT,
	},
	version: INBOX_VERSIONS.V3,
	viewType: undefined,
	counts: {},
	csOrgId: undefined,
	searchTerm: '',
};

const unsetAllFilters = (filter: InboxMessageFilters): InboxMessageFilters => ({
	newMessagesOnly: false,
	showSnoozedOnly: false,
	showReminderOnly: false,
	sentiment: filter.sentiment.map(
		(sentiment) => new FilterDefinition({ ...sentiment, isVisible: false })
	),
	campaigns: filter.campaigns.map(
		(campaigns) => new FilterDefinition({ ...campaigns, isVisible: false })
	),
	teamMembers: filter.teamMembers.map(
		(teamMember) => new FilterDefinition({ ...teamMember, isVisible: false })
	),
	tags: filter.tags.map(
		(tags) => new FilterDefinition({ ...tags, isVisible: false, isExclude: false })
	),
	reminderStatuses: [],
	lastMessageTypes: [],
	searchContext: SEARCH_CONTEXTS.ALL_MESSAGES_CONTEXT,
});

function processLoadMoreResponse(
	state: InboxModelState<InboxMessageFilters>,
	payload: LoadMorePaginatedResponse<InboxModel>
): InboxModelState<InboxMessageFilters> {
	//if the state offset is greater than 0 we are going to append the page content
	const isPageAddition = state.offset > 0;
	const newPage = payload.results.map((x) => ({ ...x, isSubmitting: false })) ?? [];

	return {
		...state,
		data: isPageAddition ? [...(state.data ?? []), ...newPage] : newPage,
		loading: false,
		offset: payload.offset,
		hasMore: payload.hasMore,
	};
}

const reducer:
	| Reducer<InboxModelState<InboxMessageFilters>, InboxMessagesActions<InboxMessageFilters>>
	| undefined = (state, action) => {
	if (!state) {
		state = stateBase;
	}
	let threadIds: Set<string>;
	switch (action.type) {
		case InboxMessagesUpdateVersionActionType:
			return {
				...state,
				version: action.version,
				data: [],
				loading: true,
				counts: {},
				searchTerm: '',
			};
		case InboxMessagesLoadActionType:
			return {
				...state,
				...{
					loading: true,
				},
			};
		case InboxMessagesLoadSuccessActionType:
			return processLoadMoreResponse(state, action.payload);
		case InboxMessagesCountLoadSuccessActionType:
			return { ...state, counts: { ...state.counts, ...action.payload } };
		case InboxMessagesLoadFailedActionType:
			return {
				...state,
				data: undefined,
				totalCount: -1,
				error: true,
			};
		case InboxMessagesUpdatePaginationActionType:
			return {
				...state,
				offset: action.offset ?? DEFAULT_OFFSET,
				pageSize: action.pageSize ?? DEFAULT_PAGE_SIZE,
			};
		case InboxPageLoadSuccessActionType:
			return {
				...state,
				filter: action.filters,
				viewType: action.viewType,
				csOrgId: action.csOrgId,
			};
		case InboxMessagesUpdateFilterActionType:
			return {
				...state,
				filter: { ...state.filter, ...action.filter },
				offset: DEFAULT_OFFSET,
				pageSize: DEFAULT_PAGE_SIZE,
			};
		case InboxMessagesResetFilterInStoreActionType:
			return {
				...state,
				filter: unsetAllFilters(state.filter),
				offset: DEFAULT_OFFSET,
				pageSize: DEFAULT_PAGE_SIZE,
			};
		case InboxMessagesUpdateSuccessActionType:
			return {
				...state,
				data: state.data?.map((message) =>
					message.id == action.threadId ? { ...message, ...action.update } : message
				),
				counts: { ...state.counts, ...action.counts },
			};
		case InboxMessagesUpdateViewSuccessActionType:
			return {
				...state,
				data: [],
				viewType: action.viewType,
			};
		case InboxMessagesDismissSuccessActionType:
			threadIds = new Set(action.threadIds);
			return {
				...state,
				data: state.data?.filter((message) => !threadIds.has(message.threadId)),
				counts: { ...state.counts, ...action.counts },
			};
		case InboxMessagesSendMessageSuccessActionType:
			return {
				...state,
				data: state.data?.filter((message) => message.threadId != action.threadId),
				counts: { ...state.counts, ...action.counts },
			};
		case InboxMessagesMigrateConnectionSuccessActionType: {
			const migratedConnections = new Set(
				action.migrationResponse.migratableConnections.map((connection) => connection.id)
			);
			const [migratedThreads, nonMigratedThreads] = partition(state.data ?? [], (message) =>
				migratedConnections.has(message.connectionId)
			);
			return {
				...state,
				data: nonMigratedThreads,
				prevMigratedThreads: migratedThreads,
			};
		}
		case ApplyInboxMessagesMarkAsReadSuccessActionType:
			threadIds = new Set(action.threadIds);
			return {
				...state,
				//if we are in the new inbox's unread tab, remove the messages from the list on success. Otherwise just mark them as read
				data:
					state.filter.newMessagesOnly &&
					action.isRead &&
					state.version === INBOX_VERSIONS.V4
						? state.data?.filter((message) => !threadIds.has(message.threadId))
						: state.data?.map((message) =>
								threadIds.has(message.threadId)
									? { ...message, isRead: action.isRead }
									: message
						  ),
			};
		case InboxMessagesModifyMessageTagsActionType:
			return {
				...state,
				data: state.data?.map((message) =>
					action.threadId == message.threadId
						? { ...message, tags: action.tags }
						: message
				),
			};
		case InboxMessagesSendAutomatedMessageActionType:
		case InboxMessagesSendManualMessageActionType:
			return {
				...state,
				data: state.data?.map((message) =>
					action.threadId == message.threadId
						? { ...message, isSubmitting: true }
						: message
				),
			};
		case InboxMessagesSendMessageFailedActionType:
			return {
				...state,
				data: state.data?.map((message) =>
					action.threadId == message.threadId
						? { ...message, isSubmitting: false }
						: message
				),
			};
		case InboxMessagesUpdateSearchTermActionType:
			return {
				...state,
				searchTerm: action.searchTerm,
				offset: DEFAULT_OFFSET,
			};
		default:
			return state;
	}
};

export default { [INBOX_MESSAGES.toLowerCase()]: reducer };
