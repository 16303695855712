// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fZpc8GOPwhtoBd6Ag5Wq {\n  margin-left: 0;\n}\n.tqcReP_1aFhOj8N91hxq {\n  line-height: 1;\n}\n.EGlHp319fCdREL_rq8lK.EGlHp319fCdREL_rq8lK {\n  max-width: 350px;\n}\n.EGlHp319fCdREL_rq8lK.EGlHp319fCdREL_rq8lK .copilot-typography {\n  color: #ffffff;\n}\n.i4N1Z9hakLXAmhb8aHKh .copilot-popover-inner {\n  background: #1b2635;\n}\n.i4N1Z9hakLXAmhb8aHKh .copilot-popover-arrow::before {\n  background-color: #1b2635;\n}\n", "",{"version":3,"sources":["webpack://./../../common/pages/campaignDashboard/searchList/replyPredictionHeader.module.less"],"names":[],"mappings":"AAEA;EACC,cAAA;AADD;AAIA;EACC,cAAA;AAFD;AAKA;EACC,gBAAA;AAHD;AAEA;EAIE,cAAA;AAHF;AAOA;EAEE,mBAAA;AANF;AAIA;EAKE,yBAAA;AANF","sourcesContent":["@popup-bg: #1b2635;\n@popup-font-color: #ffffff;\n.replyPredictionAiIcon {\n\tmargin-left: 0;\n}\n\n.replyPredictionHeaderText {\n\tline-height: 1;\n}\n\n.replyPredictionPopoverContent.replyPredictionPopoverContent {\n\tmax-width: 350px;\n\n\t:global(.copilot-typography) {\n\t\tcolor: @popup-font-color;\n\t}\n}\n\n.replyPredictionPopover {\n\t:global(.copilot-popover-inner) {\n\t\tbackground: @popup-bg;\n\t}\n\t:global(.copilot-popover-arrow)::before {\n\t\tbackground-color: @popup-bg;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"replyPredictionAiIcon": "fZpc8GOPwhtoBd6Ag5Wq",
	"replyPredictionHeaderText": "tqcReP_1aFhOj8N91hxq",
	"replyPredictionPopoverContent": "EGlHp319fCdREL_rq8lK",
	"replyPredictionPopover": "i4N1Z9hakLXAmhb8aHKh"
};
export default ___CSS_LOADER_EXPORT___;
