import styles from './aiIcon.module.less';

type AiIconProps = {
	className?: string;
	noSpace?: boolean;
};

/**
 * Icon for ai features
 * @param param0
 * @returns
 */
export function AiIcon({ className = '', noSpace = false }: AiIconProps) {
	return (
		<div className={[styles.aiIcon, className, noSpace ? styles.noSpace : ''].join(' ')}>
			<div className={styles.aiSuffixSeparator} />
			<span className={styles.aiSuffixText}>AI</span>
		</div>
	);
}
