import { FC, useState } from 'react';
import { Button, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AiFeatureIcon from '@copilot/common/components/icon/aiFeatureIcon';
import { PromptGenerateArea } from './promptGenerateArea';
import { WriteFlowDropdown } from './promptMenu/writeFlowDropdown';
import { EditFlowDropdown } from './promptMenu/editFlowDropdown';
import { useSmartReplyPrompts } from '@copilot/common/hooks/smartReply/prompts';
import styled from 'styled-components';
import Popover from '@copilot/common/components/containers/tooltips/popover';
import styles from './smartReply.module.less';
import { PromptMenuHeader } from './promptMenu/promptMenu';
import { SmartReplyPromptCategory } from './promptMenu/promptMenuTypes';
import { isNil } from 'lodash';
import { SMART_REPLY_BUTTON_TEXT } from './constants';
import { SMART_REPLY_TRACKING_ID } from '@copilot/common/tracking/userpilotEventConsts';
import { AiButton } from '@copilot/common/components/ai/aiButton/aiButton';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';

/** Interface Smart Reply Button Props */
export type SmartReplyButtonPropsType = Readonly<{
	/**
	 * Whether or not the parents text area has a message
	 */
	hasMessage: boolean;
	/**
	 * when the ai assistance is not available for the user
	 */
	isAvailable: boolean;
	/**
	 * Update whether the AI assistant is currently active
	 */
	setIsActive: (update: boolean) => void;
	/**
	 * Whether the ai assistant is currently active
	 */
	isActive: boolean;
	/**
	 * Callback when the ai assistance is not available for the user
	 */
	onNotAvailable: () => void;
	/**
	 * Whether the user can regenerate
	 */
	canRegenerate: boolean;
	/**
	 * Callback to retry an AI query
	 */
	onRegenerate: () => void;
	/**
	 * Function called when user creates a custom prompt
	 */
	onSubmitCustomPrompt: (prompt: string, isEditing: boolean) => void;
	/**
	 * Function called when user selects a preset prompt
	 */
	onSelectPreset: (promptKey: string) => void;
	/**
	 * Whether the button is disabled
	 */
	disabled?: boolean;
	/**
	 * The prompt category that currently applies to the conversation
	 */
	smartReplyPromptCategory?: SmartReplyPromptCategory;
}>;

/**
 * Component to represent the Smart Reply Button
 * @param props
 */
export const SmartReplyButton: FC<SmartReplyButtonPropsType> = (props) => {
	const {
		hasMessage,
		isAvailable,
		isActive,
		setIsActive,
		onNotAvailable,
		onSubmitCustomPrompt,
		onSelectPreset,
		disabled,
		smartReplyPromptCategory,
	} = props;
	const iconColor = disabled ? 'inherit' : undefined;
	const { isNewAiUiFeatureEnabled } = useFeatureFlags();

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isWritingCustomPrompt, setIsWritingCustomPrompt] = useState<boolean>(false);

	const { writePrompts, editPrompts } = useSmartReplyPrompts();

	const writePlaceholder =
		'Try asking our AI something like ‘Pitch the importance of outbound sales to a business owner’';
	const writeButtonText = 'Write';
	const editPlaceholder =
		'Write an instruction to improve your message.\nExamples may include:\n- Writing in different languages\n- Writing for a different audience';
	const editButtonText = 'Make Edits';

	const suggestedPrompts = writePrompts.map((prompt) => {
		return {
			...prompt,
			isSuggested: !isNil(smartReplyPromptCategory)
				? prompt.useCases?.includes(smartReplyPromptCategory)
				: false,
		};
	});

	// Popover switching for Writing A Prompt
	const smartReplyWriteFlow = isWritingCustomPrompt ? (
		<PromptGenerateArea
			onSubmitCustomPrompt={(prompt) => onSubmitCustomPrompt(prompt, isEditing)}
			onCancel={() => {
				setIsWritingCustomPrompt(false);
				setIsActive(false);
			}}
			placeholderText={writePlaceholder}
			submitButtonText={writeButtonText}
		/>
	) : (
		<WriteFlowDropdown
			prompts={suggestedPrompts}
			onSelectPreset={onSelectPreset}
			setIsWritingCustomPrompt={setIsWritingCustomPrompt}
		/>
	);

	// Popover switching for Editing a message with prompt
	const smartReplyEditFlow = isWritingCustomPrompt ? (
		<PromptGenerateArea
			onSubmitCustomPrompt={(prompt) => onSubmitCustomPrompt(prompt, isEditing)}
			onCancel={() => {
				setIsWritingCustomPrompt(false);
				setIsEditing(false);
				setIsActive(false);
			}}
			placeholderText={isEditing ? editPlaceholder : writePlaceholder}
			submitButtonText={isEditing ? editButtonText : writeButtonText}
		/>
	) : (
		<>
			<PromptMenuHeader copy="Edit a message with AI" />
			<EditFlowDropdown
				prompts={editPrompts}
				onSelectPreset={onSelectPreset}
				setIsWritingCustomPrompt={setIsWritingCustomPrompt}
				setIsEditing={setIsEditing}
			/>

			<PromptMenuHeader copy="Write a message with AI" />
			<WriteFlowDropdown
				prompts={writePrompts}
				onSelectPreset={onSelectPreset}
				setIsWritingCustomPrompt={setIsWritingCustomPrompt}
			/>
		</>
	);

	const popoverContent = hasMessage ? smartReplyEditFlow : smartReplyWriteFlow;

	return (
		<Space size={'small'}>
			<StyledPopover
				content={popoverContent}
				trigger="click"
				placement="bottomLeft"
				open={disabled === true || !isAvailable ? false : undefined}
				className={styles.smartReplyPromptContainer}
			>
				{isNewAiUiFeatureEnabled ? (
					<AiButton
						isDropdownTrigger
						onClick={() => {
							if (!isAvailable) return onNotAvailable();
							setIsActive(!isActive);
						}}
						disabled={disabled}
						data-tracking-id={SMART_REPLY_TRACKING_ID}
					>
						{SMART_REPLY_BUTTON_TEXT}
					</AiButton>
				) : (
					<StyledButton
						onClick={() => {
							if (!isAvailable) return onNotAvailable();
							setIsActive(!isActive);
						}}
						disabled={disabled}
						data-tracking-id={SMART_REPLY_TRACKING_ID}
					>
						<Space size={3}>
							<AiFeatureIcon color={iconColor} />
							{hasMessage ? 'AI edits' : SMART_REPLY_BUTTON_TEXT}
							<DownOutlined style={{ fontSize: '12px', marginLeft: '5px' }} />
						</Space>
					</StyledButton>
				)}
			</StyledPopover>
		</Space>
	);
};

const StyledButton = styled(Button)`
	border-color: ${(props) => props.theme['@PRIMARY-6']};
`;

const StyledPopover = styled(Popover)`
	border-radius: 10px;

	.${(props) => props.theme['@ant-prefix']}-popover-arrow-content {
		display: none;
	}
`;
