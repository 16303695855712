// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h5EZDoThLUU1S8MtRlhE {\n  color: #8525e3;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 1;\n}\n.FQB2Jr2X138UBAO9CPAK {\n  width: 4px;\n  height: 4px;\n  border-radius: 100%;\n  background-color: #1677ff;\n  margin-right: 4px;\n}\n.n6yOrQq1MVNzuYaXEMrQ {\n  display: flex;\n  align-items: center;\n  margin-left: 8px;\n}\n.n6yOrQq1MVNzuYaXEMrQ.weANqi_6j_5NkcYSir12 {\n  margin-left: 0;\n}\nbutton:disabled .FQB2Jr2X138UBAO9CPAK {\n  background-color: rgba(0, 0, 0, 0.25);\n}\nbutton:disabled .h5EZDoThLUU1S8MtRlhE {\n  color: rgba(0, 0, 0, 0.25);\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/ai/aiIcon/aiIcon.module.less"],"names":[],"mappings":"AAIA;EACC,cAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAHD;AAMA;EACC,UAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;AAJD;AAOA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;AALD;AAMC;EACC,cAAA;AAJF;AAQA;EAEE,qCAAA;AAPF;AAKA;EAKE,0BAAA;AAPF","sourcesContent":["@ai-suffix-color: #8525e3;\n@ai-separator-color: #1677ff;\n@disabled-color: rgba(0, 0, 0, 0.25);\n\n.aiSuffixText {\n\tcolor: @ai-suffix-color;\n\tfont-weight: 600;\n\tfont-size: 12px;\n\tline-height: 1;\n}\n\n.aiSuffixSeparator {\n\twidth: 4px;\n\theight: 4px;\n\tborder-radius: 100%;\n\tbackground-color: @ai-separator-color;\n\tmargin-right: 4px;\n}\n\n.aiIcon {\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-left: 8px;\n\t&.noSpace {\n\t\tmargin-left: 0;\n\t}\n}\n\nbutton:disabled {\n\t.aiSuffixSeparator {\n\t\tbackground-color: @disabled-color;\n\t}\n\t.aiSuffixText {\n\t\tcolor: @disabled-color;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aiSuffixText": "h5EZDoThLUU1S8MtRlhE",
	"aiSuffixSeparator": "FQB2Jr2X138UBAO9CPAK",
	"aiIcon": "n6yOrQq1MVNzuYaXEMrQ",
	"noSpace": "weANqi_6j_5NkcYSir12"
};
export default ___CSS_LOADER_EXPORT___;
