// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qr8CzUJIuUW5H11b1eXF {\n  background-color: white;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  padding: 12px;\n}\n.qr8CzUJIuUW5H11b1eXF h4 {\n  color: #0958d9;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  /* 157.143% */\n  margin-top: 0;\n}\n.qr8CzUJIuUW5H11b1eXF .wK4KkT5n5QmcNEvjxoVO {\n  margin-bottom: 16px;\n}\n.qr8CzUJIuUW5H11b1eXF .wK4KkT5n5QmcNEvjxoVO * {\n  margin: 0;\n}\n.ljx_M7X7QR43fDTspds2 {\n  max-width: 360px;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/IntentAnalysisContactDrawerSection/IntentAnalysisContactDrawerSection.module.less"],"names":[],"mappings":"AAGA;EACC,uBAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;EAeA,aAAA;AAhBD;AAHA;EAME,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAA,aAAa;EACb,aAAA;AACF;AAZA;EAcE,mBAAA;AACF;AAfA;EAgBG,SAAA;AAEH;AAIA;EACC,gBAAA;AAFD","sourcesContent":["@import '../../../styles/common-variables.less';\n\n@intent-analysis-header-color: @colorPrimaryActive;\n.intentWrapper {\n\tbackground-color: white;\n\tposition: sticky;\n\ttop: 0;\n\tz-index: 1;\n\th4 {\n\t\tcolor: @intent-analysis-header-color;\n\t\tfont-size: 14px;\n\t\tfont-style: normal;\n\t\tfont-weight: 600;\n\t\tline-height: 22px; /* 157.143% */\n\t\tmargin-top: 0;\n\t}\n\t.intentTitleSection {\n\t\tmargin-bottom: 16px;\n\t\t* > {\n\t\t\tmargin: 0;\n\t\t}\n\t}\n\tpadding: 12px;\n}\n\n.helpToolTip {\n\tmax-width: 360px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intentWrapper": "qr8CzUJIuUW5H11b1eXF",
	"intentTitleSection": "wK4KkT5n5QmcNEvjxoVO",
	"helpToolTip": "ljx_M7X7QR43fDTspds2"
};
export default ___CSS_LOADER_EXPORT___;
