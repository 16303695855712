// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qfZ4AuIGuHxFR5_2H2DO {\n  margin: 0;\n}\n.qfZ4AuIGuHxFR5_2H2DO .copilot-progress-bg {\n  background: linear-gradient(to right, #8525e3, #6124d5, #04befe, #04befe, #6124d5, #8525e3);\n  background-size: 200% 100%;\n  background-position: 0% 0%;\n  transition: background-position 0.6s ease-in-out;\n}\n.qfZ4AuIGuHxFR5_2H2DO .copilot-progress-inner {\n  background: #e3e6ec;\n}\n.qfZ4AuIGuHxFR5_2H2DO:hover .copilot-progress-bg {\n  background-position: 100% 0%;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/ai/aiProgressBar/aiProgressBar.module.less","webpack://./../../common/components/ai/ai.mixins.less"],"names":[],"mappings":"AAIA;EACC,SAAA;AAHD;AAEA;ECQC,2FAAA;EACA,0BAAA;EACA,0BAAA;EACA,gDAAA;ADPD;AAJA;EAME,mBAAA;AACF;AACC;ECOA,4BAAA;ADLD","sourcesContent":["@import '../ai.mixins.less';\n@hover-animation-duration: 0.6s;\n@bar-bg: #e3e6ec;\n\n.aiProgressBar {\n\tmargin: 0;\n\t:global(.copilot-progress-bg) {\n\t\t.ai-gradient-animation-base(@hover-animation-duration);\n\t}\n\t:global(.copilot-progress-inner) {\n\t\tbackground: @bar-bg;\n\t}\n\t&:hover {\n\t\t:global(.copilot-progress-bg) {\n\t\t\t.ai-gradient-animation-hover();\n\t\t}\n\t}\n}\n","@ai-animation-gradient: linear-gradient(\n\tto right,\n\t#8525e3,\n\t#6124d5,\n\t#04befe,\n\t#04befe,\n\t#6124d5,\n\t#8525e3\n);\n@ai-animation-duration: 0.3s;\n\n.ai-gradient-animation-base(@duration: @ai-animation-duration) {\n\tbackground: @ai-animation-gradient;\n\tbackground-size: 200% 100%;\n\tbackground-position: 0% 0%;\n\ttransition: background-position @duration ease-in-out;\n}\n\n.ai-gradient-animation-hover() {\n\tbackground-position: 100% 0%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aiProgressBar": "qfZ4AuIGuHxFR5_2H2DO"
};
export default ___CSS_LOADER_EXPORT___;
