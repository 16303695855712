import { Flex, Typography } from 'antd';
import { Config } from '@copilot/common/config';
import Popover from '@copilot/common/components/containers/tooltips/popover';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';
import { AiIcon } from '@copilot/common/components/ai/aiIcon/aiIcon';
import styles from './replyPredictionHeader.module.less';

/**
 * [Presentation] Reply Prediction header + info icon
 * @constructor
 */
export function ReplyPredictionHeader() {
	const { isNewAiUiFeatureEnabled } = useFeatureFlags();
	function getInfoIcon() {
		return (
			<Popover
				overlayClassName={styles.replyPredictionPopover}
				content={
					<div className={styles.replyPredictionPopoverContent}>
						<Typography.Paragraph>
							Prospects that show a higher likelihood of reply are surfaced at the top
							of your search list. Invites are prioritized to them first.
						</Typography.Paragraph>
						{Config.replyPredictionArticleURL && (
							<Typography.Link
								href={Config.replyPredictionArticleURL}
								target="_blank"
								strong
								underline
							>
								Learn more about{' '}
								{isNewAiUiFeatureEnabled
									? 'Reply Prediction'
									: 'Reply Prediction AI'}
							</Typography.Link>
						)}
					</div>
				}
				trigger="click"
			>
				<Typography.Link>
					<QuestionCircleOutlined />
				</Typography.Link>
			</Popover>
		);
	}
	return (
		<Flex gap={8} align="center">
			<span className={styles.replyPredictionHeaderText}>
				{isNewAiUiFeatureEnabled ? 'Reply Prediction' : 'Reply Prediction AI'}
			</span>
			{isNewAiUiFeatureEnabled && <AiIcon className={styles.replyPredictionAiIcon} />}
			{getInfoIcon()}
		</Flex>
	);
}
