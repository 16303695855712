import BaseDataManager, { LoadMorePaginationObject, PaginationObject } from './base';
import {
	ConversationResponse,
	ConversationWithTargetProfileResponse,
	InboxResponse,
	LoadMorePaginatedResponse,
	PaginatedResponse,
} from '../responses/interface';
import { InboxModel } from '../responses/models/inbox';
import { SentimentMap } from '@copilot/common/store/models/const/enum';
import { FiltersRequestModel, InboxFilterRequestModel, InboxDisplayType } from '../requests/models';
import { isNull, mapValues } from 'lodash';
import { LoadMorePaginatedResultModel } from '../responses/models/loadMorePaginatedResultModel';
import { PaginatedResultModel } from '../responses/models/paginatedResult';
import isNil from 'lodash/isNil';

class InboxManager extends BaseDataManager {
	constructor() {
		super();
		this.getMessageCounts = this.getMessageCounts.bind(this);
		this.getMessageCountByCategory = this.getMessageCountByCategory.bind(this);
		this.getMessagesWithQueryV4 = this.getMessagesWithQueryV4.bind(this);
	}

	private convertResponseNullToUndefined(item: InboxResponse): InboxResponse {
		return mapValues(item, (value) => (isNull(value) ? undefined : value));
	}

	private _getMessagesAbortController: AbortController | undefined = undefined;
	private _getMessagesV4AbortController: AbortController | undefined = undefined;

	/**
	 * Load inbox messages with query params
	 * @param viewType Inbox's view type
	 * @param filters
	 * @param query
	 * @param searchTerm
	 * @param organizationId
	 * @param sortBy
	 */
	public async getMessagesWithQueryV4(
		viewType: InboxDisplayType,
		filters: Partial<InboxFilterRequestModel>,
		query: LoadMorePaginationObject,
		searchTerm: string,
		organizationId?: string,
		sortBy?: string
	) {
		if (!isNil(this._getMessagesV4AbortController)) {
			this._getMessagesV4AbortController?.abort();
		}

		this._getMessagesV4AbortController = new AbortController();
		const url = this.paginate(this.BACKEND_ROUTES.INBOX.V4, query);
		const request: Partial<FiltersRequestModel> = {
			ViewType: viewType,
			FilterBy: filters,
			SearchTerm: searchTerm,
			SortBy: sortBy,
		};

		const options = organizationId ? { params: { oid: organizationId } } : {};

		try {
			const response = await this.RequestManager.post<
				LoadMorePaginatedResponse<InboxResponse>
			>(url, request, { ...options, signal: this._getMessagesV4AbortController.signal });
			const updatedResult = response.data.results.map(this.convertResponseNullToUndefined);
			return new LoadMorePaginatedResultModel(
				{
					...response.data,
					results: updatedResult,
				},
				InboxModel
			);
		} catch {
			return new LoadMorePaginatedResultModel(null, InboxModel);
		} finally {
			this._getMessagesV4AbortController = undefined;
		}
	}

	/**
	 * Load inbox messages with query params
	 * @param viewType Inbox's view type
	 * @param filters
	 * @param query
	 * @param searchTerm
	 * @param organizationId
	 * @param sortBy
	 */
	getMessagesWithQuery = (
		viewType: InboxDisplayType,
		filters: Partial<InboxFilterRequestModel>,
		query: LoadMorePaginationObject,
		searchTerm: string,
		organizationId?: string,
		sortBy?: string
	) => {
		if (!isNil(this._getMessagesAbortController)) {
			this._getMessagesAbortController.abort();
		}
		this._getMessagesAbortController = new AbortController();
		const url = this.paginate(
			this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'v3'),
			query
		);
		const request: Partial<FiltersRequestModel> = {
			ViewType: viewType,
			FilterBy: filters,
			SearchTerm: searchTerm,
			SortBy: sortBy,
		};
		// TODO: COPILOT-3055 make orgId param mandatory
		const options = organizationId ? { params: { oid: organizationId } } : {};

		return this.RequestManager.post<LoadMorePaginatedResponse<InboxResponse>>(url, request, {
			...options,
			signal: this._getMessagesAbortController.signal,
		})
			.then((response) => {
				const updatedResult = response.data.results.map(
					this.convertResponseNullToUndefined
				);
				return new LoadMorePaginatedResultModel(
					{ ...response.data, results: updatedResult },
					InboxModel
				);
			})
			.catch(() => new LoadMorePaginatedResultModel(null, InboxModel))
			.finally(() => {
				this._getMessagesAbortController = undefined;
			});
	};

	/**
	 * Get message counts
	 * @param {string | null} orgId Return message count for the organization if provided. Otherwise only the count for the current user is returned
	 * @param {InboxDisplayType} viewType inbox view type
	 * @param {Partial<InboxFilterRequestModel>} filters Applied InboxFilters
	 *
	 * Making HTTP POST request for 'getting' count as we want to pass filters in body as it is complex and nested
	 */
	public async getMessageCounts(filters: Partial<InboxFilterRequestModel>) {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.V4, 'counts');
		const response = await this.RequestManager.post<{ [k: string]: number }>(url, {
			ViewType: InboxDisplayType.Email,
			FilterBy: filters,
		});
		return response.data;
	}

	/**
	 * Get message counts
	 * @param {string | null} orgId Return message count for the organization if provided. Otherwise only the count for the current user is returned
	 * @param {InboxDisplayType} viewType inbox view type
	 * @param {Partial<InboxFilterRequestModel>} filters Applied InboxFilters
	 *
	 * Making HTTP POST request for 'getting' count as we want to pass filters in body as it is complex and nested
	 */
	public getMessageCountByCategory(
		orgId: string | undefined,
		viewType: InboxDisplayType,
		filters: Partial<InboxFilterRequestModel>
	) {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'count');
		return this.RequestManager.post<{ [k: string]: number }>(
			url,
			{ ViewType: viewType, FilterBy: filters },
			{
				params: { oid: orgId },
			}
		).then((response) => response.data);
	}

	/**
	 * Archives inbox messages
	 * @param threadId  thread Ids of messages
	 */
	archiveMessage = (threadId: string[]) => {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'thread', 'archive');
		const data = { archive: true };
		return this.RequestManager.post(url, threadId, { params: data }).then(() => true);
	};

	/**
	 * Send an automated message reply
	 * This route sends a request to run a campaign node
	 * @param campaignMemberId Sender's campaign member Id
	 * @param contactId Recipient contact Id
	 * @param message Content of the message
	 * @param nodeId Id of the automated steps to run
	 */
	sendAutomationReply = (
		campaignMemberId: string,
		contactId: string,
		message: string,
		nodeId: string
	) => {
		const url = this.BACKEND_ROUTES.INBOX.Execute;
		const request: {
			campaignMemberId: string;
			contactId: string;
			messageOverride: string;
			targetNodeId?: string;
		} = {
			campaignMemberId,
			contactId,
			messageOverride: message,
		};
		if (nodeId) request.targetNodeId = nodeId;
		return this.RequestManager.post(url, request);
	};

	/**
	 * Update message read status
	 * @param threadIds  thread Ids
	 * @param isRead true if we want to set messages to Read, false to set it unread
	 */
	setRead = (threadIds: string[], isRead: boolean): Promise<boolean> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'setread');
		return this.RequestManager.post(url, threadIds, { params: { isRead } }).then(() => true);
	};

	/**
	 * get message unread count
	 */
	getUnreadCount = (): Promise<number> => {
		//TODO may want to have this update to use orgmemberid route so admins can see others count
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'unread');
		return this.RequestManager.get<number>(url).then((response) => response.data);
	};

	/**
	 * Snooze message thread
	 * @param threadId  thread Id of message
	 * @param date snooze until
	 */
	snoozeThread = (threadId: string, date?: string): Promise<boolean> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Thread, threadId, 'snooze');

		const data = date ? { snoozeTime: date, snooze: true } : { snooze: false };
		return this.RequestManager.post(url, null, { params: data }).then(() => true);
	};

	/**
	 * Marks reminded thread as complete
	 * @param threadId thread Id of message
	 */
	completeRemindedThread = (threadId: string): Promise<boolean> => {
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Thread, threadId, 'complete');

		return this.RequestManager.post(url, null, {}).then(() => true);
	};

	/**
	 * Get a conversation for a LinkedIn Profile
	 * @param {string} profileId LinkedIn Profile Id we want to get conversation for
	 * @param {DataManagerOptions} options
	 */
	public getMessagesForLinkedInProfile = (memberProfileId: string, contactProfileId: string) => {
		const url = `${this.BACKEND_ROUTES.MESSAGE.Default}/${memberProfileId}/${contactProfileId}`;
		return this.RequestManager.get<ConversationResponse[]>(url)
			.then((response) => response.data)
			.catch((error) => {
				throw error;
			});
	};

	/**
	 * Grabs the conversation and linkedIn profile data for a org contact and member
	 * @param contactId
	 * @param memberId
	 * @returns
	 */
	public getConversationAndTargetProfileForOrgContactAndMember = (
		contactId: string,
		memberId: string
	) => {
		const url = `${this.BACKEND_ROUTES.MESSAGE.Default}/by-org-contact-and-member/${contactId}/${memberId}`;
		return this.RequestManager.get<ConversationWithTargetProfileResponse>(url)
			.then((response) => response.data)
			.catch((error) => {
				throw error;
			});
	};

	/**
	 * Send manual reply message to outbox
	 * @param {string} linkedInThreadId linkedinThreadId to submit message to
	 * @param {string} message message content
	 * @param {string} orgMemberId org member Id
	 * @param {string | undefined} templateId message templateId used for tracking
	 * @param {string | undefined} campaignId campaignId of the message receiver used for tracking
	 */
	public sendManualReply = (
		linkedInThreadId: string,
		message: string,
		orgMemberId: string,
		removeReminder: boolean,
		templateId: string | undefined,
		campaignId: string | undefined
	): Promise<boolean> => {
		const url = `${this.BACKEND_ROUTES.LINKED_IN.SendMessage}`;
		const messageData = {
			threadId: linkedInThreadId,
			message,
			orgMemberId,
			templateId,
			campaignId,
			removeReminder,
		};
		return this.RequestManager.post(url, messageData)
			.then((response) => {
				if (response.status >= 200 && response.status < 207) {
					return true;
				} else {
					return false;
				}
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	/**
	 * Categorize thread by sentiment
	 * @param sentiment sentiment to categorize to
	 * @param threadId thread id to categorize
	 */
	categorizeThread = (sentiment: SentimentMap, threadId: string) => {
		const data = { polarity: sentiment };
		const url = this.combineRoute(this.BACKEND_ROUTES.INBOX.Default, 'categorize', threadId);
		return this.RequestManager.post(url, null, { params: data })
			.then(() => true)
			.catch(() => false);
	};

	/**
	 * Get messages by category for advisor VA
	 * @param {string} category
	 * @param query
	 * @returns
	 */
	getMessagesByCategoryVA = (
		category: string,
		query: PaginationObject = new PaginationObject()
	) => {
		const url = this.paginate(
			`${this.BACKEND_ROUTES.INBOX.Default}/categorize/${category}`,
			query
		);
		return this.RequestManager.get<PaginatedResponse<InboxResponse>>(url).then((response) => {
			const updatedResult = response.data.results.map(this.convertResponseNullToUndefined);
			return new PaginatedResultModel(
				{ ...response.data, results: updatedResult },
				InboxModel
			);
		});
	};
}

const instance = new InboxManager();
export default instance;
