import { Flex, Progress } from 'antd';
import styles from './aiProgressBar.module.less';

type AiProgressBarProps = {
	percent: number;
};

/**
 * AI Progress Bar
 * @param percent - The percentage of the progress bar
 * @returns The AI Progress Bar
 */
export function AiProgressBar({ percent }: AiProgressBarProps) {
	return (
		<Flex align="center" gap="small" justify="center">
			<Progress className={styles.aiProgressBar} percent={percent} showInfo={false} />
			{percent}%
		</Flex>
	);
}
