import { INTENT_ANALYSIS_LABELS, IntentAnalysisLabelValue } from './types';
import isNil from 'lodash/isNil';

/**
 * Turns enumerated label value to a proper display value (Note: this is simple for now but may be a switch statement later)
 * @param labelValue
 * @returns
 */
export function getIntentAnalysisLabelDisplayValue(
	labelValue: IntentAnalysisLabelValue | undefined
) {
	if (isNil(labelValue)) return '';
	if (labelValue === INTENT_ANALYSIS_LABELS.URGENCY) return 'Urgent';
	return labelValue.charAt(0).toUpperCase() + labelValue.slice(1);
}
