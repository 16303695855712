import { Flex, Tooltip, Typography } from 'antd';
import { BetaTag } from '../icon/betaTag';
import { AiTag } from '../ai/aiTag/aiTag';
import styles from './IntentAnalysisContactDrawerSection.module.less';
import { IntentAnalysisData } from '@copilot/common/store/models/intentAnalysis/types';
import isNil from 'lodash/isNil';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getIntentAnalysisLabelDisplayValue } from '@copilot/common/store/models/intentAnalysis/util';

type IntentAnalysisProps = {
	intentAnalysis?: IntentAnalysisData;
};
/**
 * Intent Analysis Section for the conversation/contact drawer
 * @param param0
 * @returns
 */
export function IntentAnalysisContactDrawerSection({ intentAnalysis }: IntentAnalysisProps) {
	return isNil(intentAnalysis?.majorLabel?.aiLabel) &&
		isNil(intentAnalysis?.minorLabel?.aiLabel) ? null : (
		<div className={styles.intentWrapper}>
			<Flex
				gap={4}
				justify={'flex-start'}
				align={'center'}
				className={styles.intentTitleSection}
			>
				<BetaTag />
				<Typography.Title level={4}>Conversation Sentiment</Typography.Title>
				<Tooltip
					overlayClassName={styles.helpToolTip}
					placement="bottomLeft"
					title={
						'Our sentiment analysis highlights interested replies and flags potential disinterest based on the BANT framework. While it’s not perfect, we’re continuously training the model to improve its accuracy.'
					}
				>
					<QuestionCircleOutlined />
				</Tooltip>
			</Flex>

			<Flex justify={'flex-start'} align={'center'} gap={12}>
				{!isNil(intentAnalysis?.majorLabel?.aiLabel) && (
					<AiTag>
						{getIntentAnalysisLabelDisplayValue(intentAnalysis?.majorLabel?.aiLabel)}
					</AiTag>
				)}
				{!isNil(intentAnalysis?.minorLabel?.aiLabel) && (
					<AiTag>
						{getIntentAnalysisLabelDisplayValue(intentAnalysis?.minorLabel?.aiLabel)}
					</AiTag>
				)}
			</Flex>
		</div>
	);
}
