import { Menu, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { JSX } from 'react';
import styles from './smartReplyPromptMenu.module.less';
import { MenuProps } from 'antd/lib/menu';
import isNil from 'lodash/isNil';
import { Prompt } from './promptMenuTypes';
import { SMART_REPLY_MENU_TEST_ID } from './constants';
import { useFeatureFlags } from '@copilot/common/hooks/useFeatureFlags/useFeatureFlags';
import { AiIcon } from '@copilot/common/components/ai/aiIcon/aiIcon';

const { Text } = Typography;

type PromptMenuProps = Omit<MenuProps, 'items'> &
	Readonly<{
		/** The prompts to display */
		prompts: ReadonlyArray<Prompt>;

		/** The key and value corresponding to the custom prompt to display */
		customPrompt?: {
			key: string;
			label: string;
		};
	}>;

/**
 * [Presentational] A prompt menu for Smart Reply
 */
export function PromptMenu(props: PromptMenuProps) {
	const { prompts, customPrompt } = props;
	const { isNewAiUiFeatureEnabled } = useFeatureFlags();

	const prompt = isNewAiUiFeatureEnabled
		? prompts.map((p) => ({
				label: <MenuItem label={p.label} iconSuffix={<AiIcon noSpace />} />,
				key: p.id,
		  }))
		: prompts.map((p) => ({
				label: <MenuItem icon={<EditOutlined />} label={p.label} />,
				key: p.id,
		  }));

	const custom = isNil(customPrompt)
		? undefined
		: {
				label: <MenuItem icon={<EditOutlined />} label={customPrompt.label} />,
				key: customPrompt.key,
		  };

	const items = custom ? [...prompt, custom] : prompt;

	return (
		<Menu
			data-testid={SMART_REPLY_MENU_TEST_ID}
			items={items}
			className={styles.smartReplyMenu}
			{...props}
		/>
	);
}

/**
 * [Presentational] A prompt menu header for Smart Reply
 */
export function PromptMenuHeader({ copy }: { copy: string }) {
	return <Text className={styles.smartReplyMenuHeader}>{copy}</Text>;
}

/**
 * [Presentational] A menu item for the Smart Reply prompt menu
 */
function MenuItem({
	icon,
	label,
	iconSuffix,
}: {
	label: string;
	icon?: JSX.Element;
	iconSuffix?: JSX.Element;
}): JSX.Element {
	return (
		<Space size="small">
			{!isNil(icon) && <div className={styles.smartReplyMenuIcon}>{icon}</div>}
			<Text>{label}</Text>
			{!isNil(iconSuffix) && iconSuffix}
		</Space>
	);
}
