import { HOSTED_AUTH_FAILED_HASH, HOSTED_AUTH_SUCCESS_HASH } from '@copilot/common/tracking/hostedAuthConsts';
import { ILinkedInAuthErrors, useGenerateLinkedInAuthMutation } from '@copilot/data/graphql/_generated';
import notificationManager from '@copilot/common/utils/notificationManager';

/**
 * Hook to navigate to Unipile hosted linkedin authentication url
 * @param orgMemberId org member id of the authenticated user
 * @param redirectUrl url to redirect to after authentication success or failure
 */
export function useNavigateToHostedAuthUrl(orgMemberId: string, redirectUrl: string) {
	const [getAuthUrl] = useGenerateLinkedInAuthMutation();
	
	async function navigateToHostedAuthUrl() {
		const res = await getAuthUrl({
			variables: {
				input: {
					orgMemberId: orgMemberId,
					successRedirectUrl: `${redirectUrl}${HOSTED_AUTH_SUCCESS_HASH}`,
					failureRedirectUrl: `${redirectUrl}${HOSTED_AUTH_FAILED_HASH}`,
				},
			},
		});
		const data = res.data?.generateLinkedInAuth;
		if (data?.__typename === 'LinkedInAuthDTO') {
			window.location.replace(data.url);
		} else if (data?.__typename === 'LinkedInAuthError') {
			if (data.type === ILinkedInAuthErrors.InProgress) {
				notificationManager.showErrorNotification({
					message: 'A login attempt is currently being finalized for this user. Please wait a few moments before trying again.',
				});
			} else {
				notificationManager.showErrorNotification({
					message:
						'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
				});
			}
		} else {
			notificationManager.showErrorNotification({
				message:
					'Oops! Something went wrong. Please try again in a moment, or contact support if this problem persists.',
				description: 'Non-string url returned',
			});
		}
	}
	
	return navigateToHostedAuthUrl;
}