import { ButtonHTMLAttributes, DetailedHTMLProps, MouseEvent } from 'react';
import { AiTag } from '../aiTag/aiTag';
import { DownOutlined } from '@ant-design/icons';
import styles from './aiButton.module.less';
import { AI_TAG_SIZES, AiTagSize } from '../aiTag/types';

export type AiButtonProps = {
	children: any;
	suffixContent?: any;
	isDropdownTrigger?: boolean;
	isLoading?: boolean;
	size?: AiTagSize;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

/**
 * AI Button
 * @param children - The content of the button
 * @param suffixContent - The content to display after the main content
 * @param isDropdownTrigger - Whether the button is a dropdown trigger
 * @param isLoading - Whether the button is loading
 * @param size - The size of the button
 * @param disabled - Whether the button is disabled
 * @param onClick - The function to call when the button is clicked
 * @returns The AI Button
 */
export function AiButton({
	children,
	className,
	suffixContent,
	isDropdownTrigger = false,
	isLoading = false,
	disabled,
	onClick: handleOnClick,
	size = AI_TAG_SIZES.MEDIUM,
	...rest
}: AiButtonProps) {
	function onClick(e: MouseEvent<HTMLButtonElement>) {
		if (!isLoading && !disabled) {
			handleOnClick?.(e);
		} else {
			e.stopPropagation();
		}
	}

	return (
		<button
			{...rest}
			className={[className, styles.button].join(' ')}
			disabled={isLoading || disabled}
			onClick={onClick}
		>
			<AiTag
				isClickable
				size={size}
				isLoading={isLoading}
				suffixContent={
					<>
						{isDropdownTrigger && <DownOutlined height={11} />}
						{suffixContent}
					</>
				}
			>
				{children}
			</AiTag>
		</button>
	);
}
