import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './appLoader.module.less';

/**
 * A loading indicator that will be shown when the app is loading
 * @returns
 */
export function AppLoader() {
	return (
		<Flex justify="center" align="center" className={styles.appLoader}>
			<Spin
				size="large"
				className={styles.loaderIndicator}
				indicator={<LoadingOutlined className={styles.loaderIcon} />}
			/>
		</Flex>
	);
}
