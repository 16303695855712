import * as React from 'react';
import {
	CalendarOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	ReadOutlined,
} from '@ant-design/icons';
import { Badge, Skeleton } from 'antd';
import ConversationWrapper from '../../../../componentModels/activities/conversationWrapper/conversationWrapper';
import Notes from '../../../../componentModels/activities/notes';
import ProspectData from '../prospectData';
import {
	Contact,
	ConversationThread,
	IContactConnection,
} from '@copilot/common/store/models/redux';
import { SessionBoundModel } from 'redux-orm';
import { useSelector } from 'react-redux';
import { useLoadMessageTemplatesByOrg } from '@copilot/common/hooks/templateMessage';
import { MessageTemplateSelectors } from '@copilot/common/store/selectors/messageTemplate';
import { MessageTemplate } from '@copilot/common/store/models/redux/messageTemplate';
import { OrganizationMemberSelectors } from '@copilot/common/store/selectors/organizationMember';
import {
	ConnectionResponse,
	IHistory,
	MemberLinkedInProfileResponse,
} from '@copilot/data/responses/interface';
import moment from 'moment';
import { ProspectDrawerTrackingParams } from '../tracking';

import Tabs from '@copilot/common/components/tabs/Tabs';
import { useNewProspectDrawerReportingAndHistory } from '@copilot/common/components/drawer/wrappers/contact/data/hooks';
import { MessageFormMessageTemplate } from '@copilot/common/components/forms/messageForm/types';
import {
	ContactDrawerTabTracking,
	ContactDrawerTabs,
	ContactDrawerTabsConstants,
} from './constants';
import { AutomatedStep } from '@copilot/common/pages/inbox/ui/component/card/messageCard/types';
import styles from './contactDrawerTabbedContent.module.less';
import { createLinkedInProfileUrl } from '@copilot/common/contacts/helpers';
import ScheduledMessages from '@copilot/common/components/componentModels/activities/scheduledMessages/list';

type ContactDrawerTabbedContentProps = {
	contact: SessionBoundModel<Contact>;
	organizationId: string;
	selectedConnection?: ConnectionResponse;
	conversation: SessionBoundModel<ConversationThread>;
	contactProfile?: MemberLinkedInProfileResponse;
	isOwner: boolean;
	isMessagesLoading: boolean;
	tab?: ContactDrawerTabs;
	tabBarStyle?: React.ComponentProps<typeof Tabs>['tabBarStyle'];
	onTracking?: (params: ProspectDrawerTrackingParams) => void;
	contactConnection: IContactConnection;
	automatedSteps: AutomatedStep[] | undefined;
	onMessageSent: () => void;
};

function ContactDrawerTabbedContent({
	contact,
	organizationId,
	selectedConnection,
	conversation,
	contactProfile,
	isOwner,
	isMessagesLoading,
	tab,
	tabBarStyle,
	onTracking,
	automatedSteps,
	onMessageSent,
}: ContactDrawerTabbedContentProps) {
	useLoadMessageTemplatesByOrg(organizationId);
	const isCampaignHistoryEnabled = useNewProspectDrawerReportingAndHistory();
	const templateMessages = useSelector((state) =>
		MessageTemplateSelectors.getTemplatesByCampaignId(
			state,
			selectedConnection?.campaignId ?? ''
		)
	);

	const orgMember = useSelector((state) =>
		OrganizationMemberSelectors.getOrgMember(state, selectedConnection?.orgMemberId)
	);
	//TODO: [Nurture] Update to avoid model transformation in the component
	function getHistory(history: { campaignName: string; dates: string }[]) {
		const processedHistory: IHistory<string>[] = [];
		const totalHistory = history.length;
		history.forEach((c, idx) => {
			const isLastCampaign = idx === totalHistory - 1;
			const { campaignName } = c;
			const startDate = moment(c.dates[0] || '').format('YYYY-MM-DD');
			const endDate = moment(c.dates[c.dates.length - 1] || '').format('YYYY-MM-DD');
			processedHistory.push({
				data: campaignName,
				dates: [startDate, isLastCampaign ? 'Current' : endDate],
			});
		});
		return processedHistory;
	}

	const additionalInfo = React.useMemo(() => {
		const history = selectedConnection?.history ? getHistory(selectedConnection.history) : [];
		const campaignName = selectedConnection?.campaignName ?? '';
		const organizationMemberName = selectedConnection?.orgMemberName ?? '';
		const dateCreated = selectedConnection?.dateCreated
			? new Date(selectedConnection.dateCreated).toDateString()
			: '';
		const contactUrl = contactProfile ? createLinkedInProfileUrl(contactProfile.alias) : '';

		return {
			history,
			campaignName,
			organizationMemberName,
			dateCreated,
			contactUrl,
		};
	}, [contactProfile, selectedConnection]);

	const displayMessageTemplates: MessageFormMessageTemplate[] = React.useMemo(
		() =>
			templateMessages.map((t: SessionBoundModel<MessageTemplate>) => ({
				message: t.message,
				name: t.name,
				id: t.id,
				campaignIds: t.campaignIds,
			})),
		[templateMessages]
	);

	const trackTabClick = (tabKey: string) => {
		onTracking?.({ buttonClicked: ContactDrawerTabTracking[tabKey] });
	};

	return (
		<Tabs
			defaultActiveKey={tab ?? ContactDrawerTabsConstants.Conversation}
			tabBarStyle={tabBarStyle}
			onTabClick={trackTabClick}
			className={styles.activityTabs}
		>
			<Tabs.TabPane
				tab={
					<Badge className="offset-x-10 small">
						<MessageOutlined />
						Conversation
					</Badge>
				}
				key={ContactDrawerTabsConstants.Conversation}
			>
				{orgMember ? (
					<Skeleton loading={isMessagesLoading} active>
						<ConversationWrapper
							automatedSteps={automatedSteps}
							messageTemplates={displayMessageTemplates}
							contact={contact}
							conversation={conversation}
							orgMember={orgMember}
							isOwner={isOwner}
							onTracking={onTracking}
							campaignId={selectedConnection?.campaignId}
							onMessageSent={onMessageSent}
						/>
					</Skeleton>
				) : (
					<></>
				)}
			</Tabs.TabPane>
			<Tabs.TabPane
				tab={
					<Badge className="offset-x-10 small">
						<ReadOutlined />
						Notes
					</Badge>
				}
				key={ContactDrawerTabsConstants.Notes}
			>
				{isOwner && orgMember ? (
					<Notes contact={contact} orgMemberId={orgMember.id} />
				) : (
					<></>
				)}
			</Tabs.TabPane>
			<Tabs.TabPane
				tab={
					<Badge className="offset-x-10 small">
						<CalendarOutlined />
						Schedule Message
					</Badge>
				}
				disabled={conversation.isThreadRestricted}
				key={ContactDrawerTabsConstants.Schedules}
			>
				{isOwner && orgMember ? (
					<ScheduledMessages
						contact={contact}
						orgMemberId={orgMember.id}
						messageTemplates={displayMessageTemplates}
						onTracking={onTracking}
						campaignId={selectedConnection?.campaignId}
					/>
				) : (
					<></>
				)}
			</Tabs.TabPane>
			{isCampaignHistoryEnabled ? null : (
				<Tabs.TabPane
					tab={
						<Badge className="offset-x-10 small">
							<InfoCircleOutlined />
							Prospect Info
						</Badge>
					}
					key={ContactDrawerTabsConstants.ProspectData}
				>
					{isOwner && (
						<ProspectData additionalInfo={additionalInfo} onTracking={onTracking} />
					)}
				</Tabs.TabPane>
			)}
		</Tabs>
	);
}

export default ContactDrawerTabbedContent;
